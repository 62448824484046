import ContactForm from "../components/ContactForm/ContactForm";

const ContactPage = () => {
  return(
    <div className='page-container'>
        <ContactForm />
    </div>
  );  
};

export default ContactPage;